
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      courseData: {},
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "2px",
        },
        {
          name: "Course Name",
          key: "name",
          sortable: true,
          width: "200px",
        },
        {
          name: "Course Code.",
          key: "code",
          sortable: true,
        },
        {
          name: "Training Type",
          key: "type",
          sortable: true,
        },
        {
          name: "Is Mgt Course ?",
          key: "Mgt",
          sortable: true,
          width: "170px",
        },
        {
          name: "Course Month",
          key: "month",
          sortable: true,
        },
        {
          name: "Course Hour",
          key: "hour",
          sortable: true,
        },
        {
          name: "Total Training Days",
          key: "ttd",
          sortable: true,
          width: "170px",
        },
        {
          name: "Tranche",
          key: "tranche",
          sortable: true,
        },
        // {
        //   name: "Trainee Target",
        //   key: "target",
        //   sortable: true,
        // },
        {
          name: "Course Unit Cost",
          key: "unit_cost",
          sortable: true,
          width: "170px",
        },
      ],
      tableData: [
        {
          id: 1,
          name: "Welding & Fabrication (NE) ",
          code: "W&F (NE)",
          type: "New Entrant",
          Mgt: "Yes",
          month: "3",
          hour: "360 ",
          ttd: "74",
          tranche: "Tranche-1 ",
          target: "4115",
          unit_cost: "43413",
        },
        {
          id: 2,
          name: "Electrical & Navigation Equipment Installation (NE) ",
          code: "E&N (NE) ",
          type: "New Entrant",
          Mgt: "Yes",
          month: "3",
          hour: "360 ",
          ttd: "74 ",
          tranche: "Tranche-1 ",
          target: "4115",
          unit_cost: "31674",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
    //await this.getCourseInfo();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // async getCourseInfo() {
    //   await ApiService.post("dashboard/courses", "")
    //     .then((response) => {
    //       this.courseData = response.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
      console.log("Ok");
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
